<template>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Affecter un inventaire
                <router-link to="/audit">
                  <span class="float-right btn btn-info"> retour </span>
                </router-link>
              </div>
              <div class="container">
                <form @submit.prevent="addaudit()" novalidate >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          >Nom <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nom"
                          id="nom"
                          v-model="nom"
                          required
                        />
                      </div>
                    </div>
  
            <div class="form-group">
              <label for="last"
                          >Photo<span class="obligatoire"
                            >*</span
                          ></label>
              <div class="input-group col-md-14">
                <input
                  type="file"
                  class="form-control file-upload-info"
                  placeholder="Upload Image"
                  @change="uploadFile()"
                  ref="file"

                /> 
                    
                <span class="input-group-append">
                  <button
                    class="file-upload-browse btn btn-gradient-primary"
                    type="button"
                  >
                    Télécharger
                  </button>
                </span>
              </div>
            </div> 



            <div class="col-md-6">
                      <div class="form-group">
                        <label for="last"
                          >Choisir les produits
                          <span class="obligatoire">*</span></label
                        >

                        <v-select
                          :multiple="true"
                          :closeOnClick="false"
                          v-model="audit.produit_id"
                          :options="produits"
                          :reduce="(produits) => produits"
                          label="reference"
                        />
                      </div>
                    </div>
                    <div class="col-md-12" v-if="produits.length != 0">
                      <div class="form-group">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Réference du produit</th>
                              <th>Commentaire</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(produit, index) in audit.produit_id"
                              :key="index"
                            >
                              <td>
                                {{produit.reference}}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="commentaire"
                                  v-model="commentaire"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>





                    <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label for="last"
                          >Commentaire<span class="obligatoire"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Commentaire"
                          id="commentaire"
                          v-model="commentaire"
                          required
                        />
                      </div>
                    </div> -->
                   
  
                   
  
  
            
  
  
                   
                    <!--  col-md-6   -->
  
                    <!--  col-md-6   -->
                  </div>
  
                  <button type="submit" class="btn btn-primary">
                    + Enregistrer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from "@/axios";
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  import moment from "moment";
  export default {
    data() {
      return {
        audit: {
        nom: "",
        commentaire: [],
         produit_id: [],
         produits: [],
          file: "",
          
          photo: "",
  
        },
        produits: [],
  
        error: "",
      };
    },
  
    created() {
      this.getproduits();
  
    },
    methods: {
      uploadFile () {
          this.file = this.$refs.file.files[0];
          
          },
        getproduits() {
        HTTP.get("produits/getproduits")
          .then((response) => {
           
            this.produits = response.data;
            this.produits.forEach((produit) => {
            produit.produit_id = produit._id;
            // produit.commentaire = "";

          });
          })
          .then((err) => console.log(err));
      },
     
  
      addaudit() {
        this.audit.produits.forEach((produit) => {
        delete produit.reference;

      });
        let audittt ={
          photo:this.file,
          nom: this.nom,
          commentaire:this.commentaire,        
          produit_id:this.produit_id,
        };
        console.log(audittt);
        HTTP.post("audits/addaudit", audittt).then(
          (res) => {
            if (res.status === 200) {
              Toast.fire({
                icon: "success",
                title: "prospection ajouté",
              });
              this.$router.push("/audit");
            }
          },
          (err) => {
            this.error = err.response.data;
            console.log(error);
          }
        );
      },
  
     
    },
  };
  </script>
  